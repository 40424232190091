@import '../assets/styles/media-queries.scss';
@import '../assets/styles/components.scss';

.Paper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  padding-left: 1rem;
  padding-right: 1rem;
  @include gt-xs {
    padding-left: 2rem;
    padding-right: 2rem; }
  &.primary {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include gt-xs {
      padding-bottom: 2rem; }
    .Mainbar {
      border: 2px solid $Primary;
      border-radius: 4px;
      padding: 1rem; } }

  &.full-width {
    max-width: 100%;
    width: 100%; }
  &.full-height {
    flex: 1;
    height: 100%;
    display: flex; }
  &.no-padding {
    padding: 0; }
  &.with-sidebar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; } }
