@import '../assets/styles/media-queries.scss';
@import '../assets/styles/components.scss';

.TopBarNavigation {
  line-height: 1;
  position: relative;
  z-index: 5;
  .menu-button {
    background: $Black1;
    border: none;
    border-radius: 2rem;
    color: $Primary;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s;
    &:hover {
      background-color: $Primary;
      color: $Text; }
    &:active,
    &:focus,
    &.active {
      background-color: $PrimaryLighter;
      color: $Text; }
    svg {
      font-size: 1rem;
      width: 1rem;
      height: 1rem; } }
  .menu {
    animation: fadeIn;
    animation-duration: .4s;
    background: $Black1;
    border: 2px solid $Primary;
    border-radius: 4px;
    margin-top: 15px;
    position: absolute;
    top: 100%;
    right: -4px;
    max-width: 90vw;
    width: 200px;
    display: flex;
    flex-direction: column;
    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      right: 7px;
      border: 11px solid transparent;
      border-bottom-color: $Primary; }
    .item {
      background: transparent;
      border: none;
      border-bottom: 1px solid $Primary;
      color: $Text;
      cursor: pointer;
      padding: 15px 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      transition: all .2s;
      &:last-child {
        border-bottom: none; }
      &:hover {
        background-color: $Primary; }
      &:active,
      &:focus {
        background-color: $PrimaryLighter; }
      svg.icon {
        font-size: 1rem;
        width: 1rem;
        height: 1rem;
        margin-right: 10px; } } } }
