@import '../assets/styles/components.scss';

button.Button {
  background: transparent;
  border: 0;
  border-radius: 4px;
  color: inherit;
  cursor: pointer;
  font-size: 14px;
  font-weight: 800;
  line-height: 1;
  padding: 10px 8px;
  text-transform: uppercase;
  transition: all 0.2s;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    box-shadow: $ElevationShadow2; }
  &:active {
    box-shadow: $ElevationShadow4; }
  &:disabled {
    box-shadow: none;
    color: #dddddd;
    cursor: not-allowed; } }
