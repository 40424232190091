/// Backdrops
$BackdropDarkest: rgba(19, 21, 22, 0.90);
$BackdropDarker: rgba(19, 21, 22, 0.80);
$Backdrop: rgba(19, 21, 22, 0.66);
$BackdropLight: rgba(19, 21, 22, 0.33);

/// Item elevation
$ElevationShadow1: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
$ElevationShadow2: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
$ElevationShadow3: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(19, 8, 8, 0.12);
$ElevationShadow4: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
$ElevationShadow5: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
$ElevationShadow6: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
$ElevationShadow7: 0px 4px 5px -2px rgba(0,0,0,0.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 1px rgba(0,0,0,0.12);
$ElevationShadow8: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
$ElevationShadow9: 0px 5px 6px -3px rgba(0,0,0,0.2), 0px 9px 12px 1px rgba(0,0,0,0.14), 0px 3px 16px 2px rgba(0,0,0,0.12);
$ElevationShadow10: 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12);
$ElevationShadow12: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12);
$ElevationShadow14: 0px 7px 9px -4px rgba(0,0,0,0.2), 0px 14px 21px 2px rgba(0,0,0,0.14), 0px 5px 26px 4px rgba(0,0,0,0.12);
$ElevationShadow16: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);
$ElevationShadow18: 0px 9px 11px -5px rgba(0,0,0,0.2), 0px 18px 28px 2px rgba(0,0,0,0.14), 0px 7px 34px 6px rgba(0,0,0,0.12);
$ElevationShadow20: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 20px 31px 3px rgba(0,0,0,0.14), 0px 8px 38px 7px rgba(0,0,0,0.12);

/// Theme colors
$White1: #f2f5f6;
$White2: #e2e5e6;
$White3: #d2d5d6;
$white4: #c2c5c6;
$white5: #b2b5b6;
$Black5: #525556;
$Black4: #424546;
$Black3: #323536;
$Black2: #222526;
$Black1: #121516;

$Text: #f2f5f6;
$TextDisabled: #727576;
$PrimaryDarker: #005100;
$Primary: #087f23;
$PrimaryLighter: #4caf50;
$SecondaryDarker: #6a0080;
$Secondary: #9c27b0;
$SecondaryLighter: #d05ce3;

$Success: #4caf50;
$Danger: #f44336;
