@import '../assets/styles/media-queries.scss';

.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  &.flex {
    flex: 1; }
  svg {
    height: 60px;
    width: 60px; } }
