@import '../assets/styles/media-queries.scss';
@import '../assets/styles/components.scss';

.Login {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  * {
    transition: all .2s; }
  .logo {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    img {
      height: 100px; } }
  .alert {
    background: transparent;
    border: 1px solid $Text;
    border-radius: 4px;
    box-shadow: $ElevationShadow2;
    padding: 15px;
    margin-bottom: 15px;
    &.success {
      border-color: #4caf50;
      color: #4caf50; }
    &.error {
      border-color: #f44336;
      color: #f44336; } }
  .form {
    display: flex;
    flex-direction: column;
    input {
      background: transparent;
      border: 1px solid $Text;
      border-radius: 4px;
      color: $Text;
      line-height: 1;
      padding: 15px;
      margin-bottom: 15px;
      outline: none;
      width: 250px;
      &:focus,
      &:active {
        border-color: $Primary;
        outline: none; }
      &:disabled {
        border: 1px solid $TextDisabled;
        color: $TextDisabled; } }
    .button-container {
      display: flex;
      position: relative;
      button {
        background: transparent;
        border: 1px solid $Text;
        border-radius: 4px;
        color: $Text;
        flex: 1;
        padding: 15px;
        margin-bottom: 15px;
        &:hover {
          background: $Primary;
          border-color: $Primary;
          cursor: pointer; }
        &:active,
        &:focus {
          background: $PrimaryLighter;
          border-color: $PrimaryLighter;
          outline: none; }
        &:disabled {
          color: $TextDisabled; } }
      .Loader {
        border: 1px solid $TextDisabled;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        & > div,
        svg {
          width: 30px;
          height: 30px; } } } } }
