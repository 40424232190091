@import '../assets/styles/media-queries.scss';
@import '../assets/styles/components.scss';

.TopBar {
  color: $Primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  padding: .5rem 1rem;
  @include gt-xs {
    padding: .5rem 2rem; }
  .branding {
    flex: 1;
    h1 {
      font-size: 1rem;
      font-weight: 600; } } }
