@import '../assets/styles/media-queries.scss';
@import '../assets/styles/components.scss';

.RouteHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: $Primary;
  margin: -1rem -1rem 0;
  .heading {
    padding-left: 1rem;
    font-size: 1.5rem;
    margin: 0; }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    .return-to {
      padding: 1rem;
      color: $Primary;
      font-size: 2rem;
      line-height: 1;
      &:hover,
      &:active,
      &:focus {
        color: $PrimaryLighter; } } } }
