@import '../assets/styles/media-queries.scss';
@import '../assets/styles/components.scss';

.BottomBar {
  background: $Black1;
  border-top: 1px solid $Black3;
  display: flex;
  flex-direction: column;
  .socials {
    padding: 2em 3rem 1.5rem;
    .social {
      border: 1px solid $Primary;
      border-radius: 48px;
      box-shadow: $ElevationShadow1;
      color: $Primary;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      transition: {}
      &:hover,
      &:active {
        background: $Black2;
        border-color: $PrimaryLighter;
        color: $PrimaryLighter; }
      svg {
        font-size: 24px; } } } }
