@import '../../assets/styles/media-queries.scss';
@import '../../assets/styles/components.scss';

.page.overview .Navigation {
  flex: 1;
  display: flex;
  align-items: flex-start;
  overflow-x: hidden;
  padding: 1rem 0;
  @include gt-xs {
    align-items: center; }
  .navigation-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: scroll;
    padding-left: 1rem;
    padding-right: 1rem;
    @include gt-xs {
      flex-direction: row;
      padding-left: 2rem;
      padding-right: 2rem; }
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent; }
    &::-webkit-scrollbar-thumb {
      background: transparent; } }
  .navigation-card-container {
    background: transparent;
    margin: 0 0 1rem 0;
    @include gt-xs {
      margin: 2rem 1rem 2rem 0; }
    &:last-child {
      margin-right: 0; }
    .container-background {
      opacity: 0;
      transition: all .4s;
      z-index: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: repeating-linear-gradient(45deg, transparentize($Secondary, .95), transparentize($Secondary, .95) 10px, transparentize($White1, .95) 10px, transparentize($White1, .95) 20px); }
    .navigation-card {
      cursor: pointer;
      border: 2px solid $Secondary;
      border-radius: 4px;
      color: $Secondary;
      min-width: 0px;
      width: 100%;
      padding: 2rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      transition: all .4s;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      user-select: none;
      z-index: 1;
      @include gt-xs {
        min-width: 250px;
        width: 0%;
        padding: 7rem 2rem; }
      .card-title {
        height: 2rem;
        line-height: 1;
        font-size: 1.5rem;
        margin: 0 0 .5rem;
        transition: all .2s;
        display: flex;
        flex-direction: row;
        align-items: flex-end; }
      .card-content {
        transition: all .2s;
        @include gt-xs {
          flex: 1; } }
      .card-arrow {
        opacity: 1;
        position: absolute;
        top: 50%;
        right: 1rem;
        line-height: 1;
        transform: translateY(-50%);
        transition: all .4s;
        transition-delay: .2s;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          line-height: 1;
          font-size: 2rem;
          width: 2rem;
          height: 2rem; } }
      &:hover {
        color: $SecondaryLighter; } } }
  .navigation-card-container.true {
    .container-background {
      opacity: 1; }
    .navigation-card {
      background: linear-gradient(to right, $Black1, $Black1 50%, transparent);
      border-left-width: 10px;
      padding-left: calc(2rem - 8px);
      @include gt-xs {
        min-width: 400px;
        max-width: 400px;
        width: 100%; } } } }
