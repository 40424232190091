@import './normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,300;0,400;0,600;0,800;1,300;1,400;1,600;1,800&display=swap');
@import './assets/styles/components.scss';

body {
  background: #121516;
  color: #f2f5f6;
  margin: 0;
  font-family: "Libre Franklin", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  overflow-x: hidden;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: $Primary;
  border-radius: 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*, *:before, *:after {
  box-sizing: border-box;
}

a {
  color: $Text;
  position: relative;
  text-decoration: none;
	transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
}

a:hover {
  color: $Text;
}

